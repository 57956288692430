@import url('https://fonts.googleapis.com/css2?family=Inter&family=Titan+One&display=swap');

h1 {
    /* font-family: 'Titan One', sans-serif; */
    font-size: 75px;
}

.fuzzyfont {
    font-family: 'Titan One', sans-serif;
}


.web3modal-modal-lightbox {
    z-index: 10 !important;
}

h2 {
    font-size: 45px;
}

h2, h3, h4 {
    font-weight: bold;
    /* color: #24a9a3; */
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
